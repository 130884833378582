.page-article {
    &__img {
        margin-bottom: 20px;
    }

    &__text {
        p {
            margin-bottom: 16px;
        }
    }
}
