.page-services {
    &__list {
        background-image: url("../images/service_bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 -170px;
        padding: 100px 0;

        @media (max-width: 767px) {
            background-image: none;
        }
    }
}
