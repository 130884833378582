.partner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 255px;
    text-align: center;

    &-title {
        color: $dark-grey;

        &:hover {
            color: $dark-grey;
        }
    }

    &-img {
        margin-bottom: 20px;
    }
}
