.footer {
    flex: 0 0 auto;
    background: $green;
    color: $white;
    min-height: 136px;
    padding: 40px 0;

    &-menu {
        &__list {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }

        &__item {
            list-style: none;
            margin: 0;
        }

        &__item + &__item {
            margin-left: 24px;
            @media (max-width: 1300px) {
                margin-left: 15px;
            }

            @media (max-width: 1200px) {
                margin-left: 10px;
            }

            @media (max-width: 767px) {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        &__link {
            color: $white;
            padding-top: 38px;
            @media (max-width: 1200px) {
                font-size: 16px;
            }
            transition: .1s;

            &:hover {
                color: inherit;
                border-top: 5px solid $white;
                @media (max-width: 767px) {
                    border-top: none;
                }
            }
        }

    }
}
