.contacts {
    &-item {
        background: rgba(#9A9A9A, .2);
        border-radius: $main-border-radius;
        padding: 20px;


        @media (max-width: 992px) {
            &:not(last-child) {
                max-width: 48%;
            }

            &:last-child {
                margin: 0 auto;
            }
        }

        @media (max-width: 576px) {
            &:not(last-child) {
                max-width: 100%;
            }
        }

        &__title {
            margin-bottom: 30px;
            color: $green;

            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
        }

        &__address {
            &-wrapper {
                display: flex;
                margin-bottom: 10px;

                svg {
                    flex-shrink: 0;
                    fill: $green;
                    margin-right: 10px;
                }
            }
        }

        &__phone {
            transition: .7s;

            &:hover {
                text-decoration: underline;
            }

            &-wrapper {
                display: flex;

                svg {
                    flex-shrink: 0;
                    fill: $green;
                    margin-right: 10px;
                }
            }
        }
    }

}
