.header {
    flex: 0 0 auto;
    height: 136px;
    padding: 18px 0;
    background: #FFFFFF;
    box-shadow: 0 10px 10px rgba(22, 22, 17, 0.15);
    @media (max-width: 767px) {
        height: 100px;
        padding: 25px 0;
    }

    &-container {
        max-width: 1750px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &-logo {
        display: flex;
        align-items: center;
        max-height: 100px;

        img {
            width: 83px;
            height: 100px;
            object-fit: fill;

            @media (max-width: 1200px) {
                width: 60px;
            }

            @media (max-width: 767px) {
                height: 50px;
            }
        }

        &__title {
            margin-left: 20px;
            margin-bottom: 0;
            @media (max-width: 1300px) {
                font-size: 32px;
            }
            @media (max-width: 1200px) {
                margin-left: 10px;
                font-size: 25px;
                line-height: 32px;
            }

            @media (max-width: 767px) {
                margin-left: 10px;
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    &-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-btn {
        position: relative;
        display: none;
        width: 31px;
        height: 23px;
        border: none;
        background: transparent;
        z-index: 5;
        @media (max-width: 992px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        span {
            position: absolute;
            display: block;
            width: 100%;
            height: 3px;
            background: $light-black;
            transition: .3s ease 0s;

            &:first-child {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:last-child {
                bottom: 0;
            }
        }

        &.open {
            span {
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg) translateY(-50%);
                }

                &:nth-child(2) {
                    opacity: 0;
                    width: 0;
                }

                &:last-child {
                    bottom: 50%;
                    transform: rotate(-45deg) translateY(50%);
                }
            }
        }

    }

    &-menu {
        display: flex;
        align-items: center;
        @media (max-width: 992px) {
            display: none;
        }

        &__list {
            display: flex;
            @media (max-width: 992px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }

        &__item {
            list-style: none;
            margin: 0;
        }

        &__item + &__item {
            margin-left: 24px;
            @media (max-width: 1300px) {
                margin-left: 15px;
            }

            @media (max-width: 1200px) {
                margin-left: 10px;
            }

            @media (max-width: 992px) {
                margin-top: 20px;
                margin-left: 0;
            }
        }

        &__link {
            padding-bottom: 51px;
            @media (max-width: 992px) {
                padding-bottom: 0;
            }
            @media (max-width: 1200px) {
                font-size: 16px;
            }
            transition: .1s;

            &:hover {
                color: $green;
                border-bottom: 5px solid $green;
                @media (max-width: 992px) {
                    border-bottom: none;
                }
            }

            &.active {
                color: $green;
                border-bottom: 5px solid $green;
                @media (max-width: 992px) {
                    border-bottom: none;
                }
            }
        }

        &.open {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: $white;
            height: 85vh;
            z-index: 3;
        }
    }

    &-info {

        &__list {
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
            }
        }

    &__item {
        display: flex;
        align-items: center;

        svg {
            margin-right: 16px;
            fill: $green;
            @media (max-width: 992px) {
                fill: $white;
            }
        }

        &:first-child {
            margin-bottom: 6px;
        }

        span {
            display: block;
            font-size: 14px;
            line-height: 24px;
            color: $light-black;
            @media (max-width: 992px) {
                color: $white;
            }
        }

        a {
            font-size: 22px;
            line-height: 32px;
            color: $light-black;
            transition: .7s;
            @media (max-width: 992px) {
                color: $white;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.open {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: $green;
        height: 15vh;
        z-index: 3;
        padding: 15px;
    }

    @media (max-width: 1600px) {
        display: none;
    }
}

}
