.article {

    &-img {
        @media(max-width: 992px) {
            width: 100%;
        }
    }

    &-wrapper {
        padding: 20px 20px 15px;
    }

    &-date {
        display: block;
        color: $dark-grey;
        margin-bottom: 10px;
    }

    &-title {
        color: $green;
        margin-bottom: 20px;
    }

    &-text {
        margin-bottom: 33px;
    }

    &-more {
        color: $green;
        display: block;
        text-align: end;

        &:hover {
            text-decoration: underline;
        }
    }
}
