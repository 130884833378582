@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
}
