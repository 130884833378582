html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    border: 0;
}

a {
    color: $menu-color;
    text-decoration: none;

    @include on-event {
        color: $menu-color;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

img {
    vertical-align: top;
    max-width: 100%;
    object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
