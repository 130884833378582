.page-404 {
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            margin-bottom: 40px;
        }
    }
}
