.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 200px;
}

.page-item {
    &.active {
        color: $green;

        .page-link {
            color: $green
        }
    }
}

.page-link {
    color: rgba($green, .6);

    &:hover {
        color: $green
    }
}
