.page-promo {
    &__info {
        padding: 50px;
        background: $white;
        border-radius: $main-border-radius;
        @media (max-width: 767px) {
            padding: 40px 14px;
        }
    }
}
