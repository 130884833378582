.page-contacts {
    &__info {
        margin-bottom: 100px;
    }
}

.chiefs {
    margin-bottom: 100px;

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $white;
        padding: 30px;
        border-radius: $main-border-radius;
        margin-bottom: 20px;
        gap: 20px;
        height: 88px;
        @media (max-width: 992px) {
            height: auto;
        }

        @media (max-width: 767px) {
            gap: 10px;
        }

        @media (max-width: 574px) {
            align-items: start;
            flex-direction: column;
            padding: 20px;
            height: auto;
        }
    }

    &-position {
        max-width: 310px;
        width: 100%;
        font-weight: 700;

        @media (min-width: 574px) and (max-width: 767px) {
            max-width: 33%;
        }

        @media (max-width: 574px) {
            max-width: 100%;
        }
    }

    &-name {
        max-width: 450px;
        width: 100%;

        @media (min-width: 574px) and (max-width: 767px) {
            max-width: 33%;
        }

        @media (max-width: 574px) {
            max-width: 100%;
        }
    }

    &-phone {
        max-width: 190px;
        width: 100%;
        display: flex;
        align-items: center;

        svg {
            fill: $green;
            margin-right: 14px;

            @media (max-width: 767px) {
                margin-right: 5px;
            }
        }

        @media (min-width: 574px) and (max-width: 767px) {
            max-width: 33%;
            justify-content: end;
        }

        @media (max-width: 574px) {
            max-width: 100%;
        }
    }

}


