$main-font: 'Open Sans', sans-serif !default;

$white: white;
$black: black;
$green: #2BA43C;
$light-grey: #EEF1F1;
$dark-grey: #9A9A9A;
$light-black: #323232;
$dark-black: #061408;
$blue: #00A0E3;

$main-border-radius: 8px;


$text-color: $black !default;

$menu-color: $light-black !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

$max-width: 1140px;

$base-url: '/assets/' !default;
