.main {
    color: $white;

    &-inner {
        min-height: 878px;
        @media (max-width: 767px) {
            min-height: 468px;
        }
    }

    &-content {
        position: absolute;
        top: 30%;
        left: 5%;
        @media (max-width: 1200px) {
            left: 15px;
        }
    }

    &-title {
        margin-bottom: 24px;
    }

    &-text {
        margin-bottom: 60px;
    }

    &-link {
        padding: 14px 63px;
    }
}

.about {
    padding: 100px 0;

    &-wrapper {
        text-align: center;
    }

    &-title {
        margin-bottom: 40px;
    }

    &-text {
        max-width: 730px;
        margin: 0 auto 36px auto;
        color: $dark-grey;
    }

    &-info {
        padding: 8px 0;
        max-width: 540px;
        margin: 0 auto;
        background: rgba($green, .3);
        border-radius: $main-border-radius;
        color: $light-black;

        p {
            font-weight: 700;
        }
    }
}

.service {
    background-image: url("../images/service_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -170px;
    padding: 100px 0;

    @media (max-width: 767px) {
        padding: 80px 0;
    }

    @media (max-width: 767px) {
        background-image: none;
    }

    &-list {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        a {
            width: 33%;
        }

        @media (max-width: 1200px) {
            gap: 15px;
        }

        @media (max-width: 992px) {
            flex-wrap: wrap;
        }
    }

    &-item {
        position: relative;
        background: $white;
        border: 2px solid #9A9A9A;
        border-radius: $main-border-radius;
        padding: 78px 28px 61px;
        width: 100%;
        min-height: 350px;
        text-align: center;

        @media (max-width: 992px) {
            &:not(last-child) {
                max-width: 48%;
            }

            &:last-child {
                margin-top: 70px;
                max-width: 100%;
            }
        }

        @media (max-width: 767px) {
            padding: 58px 12px 38px;
            &:last-child {
                margin-top: 0;
                margin-bottom: 0;
            }
            margin-bottom: 60px;
        }

        @media (max-width: 576px) {
            &:not(last-child) {
                max-width: 100%;
            }
        }

        &__icon {
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
            background: $green;
            border-radius: 100%;
            width: 120px;
            height: 120px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                top: -50px;
                width: 90px;
                height: 90px;
                img {
                    width: 40px;
                }
            }
        }

        &__title {
            color: $light-black;
            margin-bottom: 30px;
        }

        &__text {
            color: $dark-grey;
        }
    }
}

.polygon {
    @media (max-width: 576px) {
        margin: 0 -15px;
    }

    &-top {
        background: $white;
        padding: 50px;
        border-radius: $main-border-radius;
        margin-bottom: 100px;

        @media (max-width: 767px) {
            margin-bottom: 80px;
        }

        @media (max-width: 576px) {
            padding: 40px 14px;
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            gap: 28px;

            @media (max-width: 992px) {
                flex-direction: column;
            }
        }

        &__left {
            max-width: 50%;
            width: 100%;

            @media (max-width: 992px) {
                max-width: 100%;
            }

            &-text {
                margin-bottom: 100px;
                color: $dark-grey;

                @media (max-width: 992px) {
                    margin-bottom: 40px;
                }
            }
        }

        &__right {
            max-width: 50%;
            width: 100%;

            @media (max-width: 992px) {
                max-width: 100%;
            }

            &-box {
                background: rgba($dark-grey, .2);
                border-radius: 20px;
                padding: 50px;
                margin: 50px 0;
                background-image: url("../images/background_info.png");
                background-repeat: no-repeat;
                background-size: cover;

                @media (max-width: 576px) {
                    padding: 40px 15px;
                    margin: 40px 0;
                }
            }
        }
    }

    &-title {
        @media (max-width: 576px) {
            padding: 0 15px;
        }
    }

    &-bottom {
        &__box {
            display: flex;
            justify-content: space-between;
            background: $white;
            padding: 50px;
            border-radius: $main-border-radius;
            margin-bottom: 100px;
            gap: 30px;

            @media (max-width: 992px) {
                padding: 40px 14px;
                flex-direction: column;
            }

            &-left {
                max-width: 50%;
                width: 100%;

                @media (max-width: 992px) {
                    max-width: 100%;
                }

                &-list {

                    li {
                        display: flex;
                        position: relative;
                        padding-left: 41px;

                        svg {
                            position: absolute;
                            top: 6px;
                            left: 0;
                            flex-shrink: 0;
                        }

                        div {

                        }
                    }

                    li:not(last-child) {
                        margin-bottom: 10px;
                    }
                }
            }

            &-right {
                max-width: 50%;
                width: 100%;

                @media (max-width: 992px) {
                    max-width: 100%;
                }

                img {
                    @media (max-width: 992px) {
                        width: 100%;
                    }
                }

            }
        }
    }
}

.news {
    padding: 50px 0;

    &-title {
        text-align: center;
    }

    &-list {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 30px;
        margin-bottom: 40px;
        flex-wrap: wrap;

        @media (max-width: 1200px) {
            gap: 15px;
        }
    }

    &-item {
        background: rgba($dark-grey, .2);
        border-radius: $main-border-radius;
        transition: .7s;
        max-width: 31%;
        list-style: none;
        margin: 0;

        &:not(last-child) {
            @media (max-width: 992px) {
                max-width: 48%;
            }

            @media (max-width: 576px) {
                max-width: 100%;
            }
        }

        &:hover {
            box-shadow: 0 12px 35px rgba(0, 0, 0, 0.15);
        }
    }

    &-more-link {
        display: block;
        margin: 0 auto;
        text-align: center;
        max-width: 235px;
        padding: 14px 63px;
    }
}

.partners {
    padding: 50px 0;

    @media (max-width: 576px) {
        margin: 0 -15px;
    }

    &-title {
        text-align: center;
    }

    &-item {
        list-style: none;
        margin: 0;
    }

    &-wrapper {
        background: $white;
        border-radius: $main-border-radius;
        padding: 50px;

        @media (max-width: 576px) {
            padding: 40px 14px;
        }
    }

    &-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }
}

.contacts {
    padding: 50px 0 100px;

    &-title {
        text-align: center;
    }

    &-item {
        list-style: none;
        margin: 0;
    }

    &-wrapper {
        background: $white;
        border-radius: $main-border-radius;
        padding: 50px;
    }

    &-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        @media (max-width: 1200px) {
            gap: 15px;
        }

        @media(max-width: 992px) {
            flex-wrap: wrap;
        }

    }
}
