.link {
    &-white {
        background: $white;
        color: $green;
        border: 1px solid $white;
        border-radius: 30px;
        transition: .7s;

        &:hover {
            background: transparent;
            color: $white;
        }
    }

    &-green {
        background: $green;
        color: $white;
        border: 1px solid $green;
        border-radius: 30px;
        transition: .7s;

        &:hover {
            background: transparent;
            color: $green;
        }
    }
}
