.breadcrumbs {
    font-size: 14px;
    margin: 20px 0;
    padding: 0;

    li {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .divider {
        font-weight: 300;
        position: relative;
        top: 0;
        display: inline-block;
        margin: 0.3rem;
        opacity: 0.35;
    }

    &--is-active {
        font-weight: bold;
    }
}
