.accordion {
    border-radius: $main-border-radius;
    background: $white;
    margin: 0;
    list-style: none;
    margin-bottom: 30px;

    animation-delay: 1s;

    @media (max-width: 767px) {
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.active {
        .accordion-body {
            height: auto;
            opacity: 1;
            padding: 40px;
            pointer-events: auto;
        }

        .accordion-header {
            svg {
                transform: rotate(180deg);
            }
        }

    }

    &-header {
        padding: 30px 18px 30px 30px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        cursor: pointer;

        @media (max-width: 767px) {
            padding: 20px;
        }

        @media (max-width: 574px) {
            gap: 10px;
        }

        svg {
            flex-shrink: 0;
        }
    }

    &-body {
        height: 0;
        opacity: 0;
        padding: 0;
        pointer-events: none;
        transition: max-height .3s ease 0s;
    }
}
