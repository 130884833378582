.clearfix::after {
    clear: both;
    content: '';
    display: table;
}

.container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
}

.hide-text {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wrapper {
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

.ibg {
    position: relative;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}

.page {
    padding: 60px 0 100px;

    @media (max-width: 767px) {
        padding: 40px 0 80px;
    }
}
