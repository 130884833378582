.page-documents {
    &__list {
        &-wrapper {
            background: $white;
            border-radius: $main-border-radius;
            padding: 50px;

            @media (max-width: 574px) {
                margin: 0 -15px;
                padding: 40px 14px;
            }
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__item {
        list-style: none;
        margin: 0;
        max-width: 48%;

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    &__link {
        color: $green;

        &:hover {
            color: $green;
            text-decoration: underline;
        }
    }
}
