body {
    background: $light-grey;
    color: $text-color;
    font-family: $main-font;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.67;

    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 1.375;
    }
}

p {
    padding-bottom: 10px;
}

ul {
    padding-bottom: 10px;

    li {
        list-style: circle;
        margin-left: 20px;
    }
}

.title {
    font-weight: 700;

    &-1 {
        font-size: 64px;
        line-height: 72px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 20px;
        }
    }

    &-2 {
        font-size: 42px;
        line-height: 54px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }

    &-3 {
        font-size: 36px;
        line-height: 49px;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 20px;
        }
    }

    &-4 {
        font-size: 24px;
        line-height: 33px;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &-5 {
        font-size: 20px;
        line-height: 27px;
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 19px;
        }
    }
}

.text {
    font-weight: 400;

    &-lead {
        font-size: 22px;
        line-height: 32px;
        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &-small {
        font-size: 14px;
        line-height: 24px;
    }

}
