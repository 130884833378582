.form {
    &-title {
        text-align: center;
    }

    &-label {
        margin-bottom: 10px;
        color: $dark-grey;
    }

    &-input {
        border-radius: $main-border-radius;
        padding: 10px;
        &__wrapper {
            display: flex;
            flex-direction: column;

            &:not(last-child) {
                margin-bottom: 30px;
            }
        }
    }

    &-textarea {
        border-radius: $main-border-radius;
        padding: 10px;
        height: 150px;
    }

    &-columns {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &-column {
        &__left,
        &__right {
            max-width: 50%;
            width: 100%;

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }

    &-btn {
        margin-top: 10px;
        padding: 15px 64px;

        @media (max-width: 574px) {
            margin: 0 auto;
            display: block;
        }
    }

    &-flash {
        position: absolute;
        background: $green;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
